<template>
  <v-container fluid>

    <EditForm
        v-if="loaded"
        ref="startPageForm"
        v-model="edited"
        :isInSublist="false"
        :model="{html:{type:'wysiwyg'}}"
        @change="viewKey++"/>

    <v-container>
      <v-row>
        <v-col>
          <v-btn :loading="loading" color="accent" @click="saveLayout">Speichern</v-btn>
        </v-col>
      </v-row>
    </v-container>


    <WysiwygView
        v-if="loaded"
        :key="viewKey"
        :wysiwyg-content="edited.html"
        no-form
    ></WysiwygView>

  </v-container>
</template>
<script>


import updateRecords from "../../helpers/updateRecords";
import {HTTP} from "@/axios";

export default {
  name: 'StartPageEditor',
  components: {
    EditForm: () => import('@/components/Forms/EditForm'),
    WysiwygView: () => import('@/components/views/WysiwygView'),
  },
  data() {
    return {
      loaded: false,
      loading: false,
      loadedContent: {json: {}},
      edited: null,
      viewKey: 0
    }
  },
  created() {
    this.loadHomeContent();
  },
  methods: {
    async loadHomeContent() {
      //reset from local storage in case of user switch without reloading
      HTTP.defaults.headers['Authorization'] = localStorage.getItem('token');
      await HTTP.get("/home-html").then(response => {
        this.loadedContent.json = response.data.json;
        this.edited = {
          json: response.data.json,
          html: response.data.html
        };
        this.loaded = true;
      });
    },
    async saveLayout() {
      this.loading = true;
      await updateRecords([{
        type: 'single',
        mutationKey: 'homeHtml',
        typeKey: 'HomeHtml',
        id: null,
        value: this.edited
      }], this);
      this.loading = false;
    }
  }
};
</script>